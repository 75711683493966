import React, { Component } from 'react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import LandingPageHeader from "../LandingPageHeader";
import FormErrors from "../FormErrors";
import Validate from "../utility/FormValidation";
import { Auth } from 'aws-amplify';

class ChangePassword extends Component {

  componentDidMount() {
    if (this.props.auth.isAuthenticated === false) {
      this.props.history.push("/");
      return;
    }
  }

  state = {
    oldpassword: "",
    newpassword: "",
    confirmpassword: "",
    errors: {
      cognito: null,
      blankfield: false,
      passwordmatch: false
    }
  }

  clearErrorState = () => {
    this.setState({
      errors: {
        cognito: null,
        blankfield: false,
        passwordmatch: false
      }
    });
  }

  handleSubmit = async event => {
    event.preventDefault();

    console.log("newpassword: " + this.state.newpassword + " confirm: " + this.state.confirmpassword);
    if (this.state.newpassword === "" || this.state.confirmpassword === "") {
      this.setState({
        errors: {
          apierrors: "Please enter password."
        }
      });
      return;
    }

    // compare Passwords
    if (this.state.newpassword != this.state.confirmpassword) {
      this.setState({
        errors: {
          apierrors: "Passwords don't match."
        }
      });
      return;
    }

    // Form validation
    this.clearErrorState();
    const error = Validate(event, this.state);
    if (error) {
      this.setState({
        errors: { ...this.state.errors, ...error }
      });
      return;
    }



    // AWS Cognito integration here
    try {
      const user = await Auth.currentAuthenticatedUser();
      console.log("user: " + user);
      await Auth.changePassword(
        user,
        this.state.oldpassword,
        this.state.newpassword
      );
      this.props.history.push("/changepasswordconfirm");
    }catch(error) {
      console.log("error: " + error);
    }
  };

  onInputChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
    document.getElementById(event.target.id).classList.remove("is-danger");
  }

  render() {
    return (
        <div className="container">
          <LandingPageHeader />
          <div className="landing-main">
            <div className="landing-title">Change Password</div>
          </div>

          <Container className="form-container">
            <Row  className="justify-content-md-center">
              <Col className="form-field">
                <FormErrors formerrors={this.state.errors} apierrors={this.state.errors.apierrors}/>
              </Col>
            </Row>
            <Row  className="justify-content-md-center">
              <Col className="form-field">
                <div className="field-area">
                  <div className="field-title">Old Password</div>
                  <input
                    className="form-input"
                    type="password"
                    id="oldpassword"
                    value={this.state.oldpassword}
                    onChange={this.onInputChange}
                  />
                </div>
              </Col>
            </Row>
            <Row  className="justify-content-md-center">
              <Col className="form-field">
                <div className="field-area">
                  <div className="field-title">New Password</div>
                  <input
                    className="form-input"
                    type="password"
                    id="newpassword"
                    value={this.state.newpassword}
                    onChange={this.onInputChange}
                  />
                </div>
              </Col>
            </Row>
            <Row  className="justify-content-md-center">
              <Col className="form-field">
                <div className="field-area">
                  <div className="field-title">Confirm Password</div>
                  <input
                    className="form-input"
                    type="password"
                    id="confirmpassword"
                    value={this.state.confirmpassword}
                    onChange={this.onInputChange}
                  />
                </div>
              </Col>
            </Row>
            <Row className="justify-content-md-center register-buttons-area">
              <Col className="form-field">
                <div>
                  <Button id="changepassword" className="landing-button register-button" onClick={ this.handleSubmit }>Submit</Button>
                </div>
                <div className="spacer-bottom" />
              </Col>
            </Row>
          </Container>
        </div>
    );
  }
}

export default ChangePassword;
