import React, { Component}  from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import RCMlogo from "../assets/images/rcm-logo.svg";
import "./LandingPage.css";

class LandingPageHeader extends Component {

  render() {
    return (
      <div className="header-container">
        <Container>
          <Row>
            <Col>
            <div className="landing-brand">
              <img src={RCMlogo} width="157" height="76" alt="rcm logo" />
            </div>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

export default LandingPageHeader;
