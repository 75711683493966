
import React, { Component } from 'react';
import ReactTags from 'react-tag-autocomplete';
import Navbar from '../Navbar';
import "../../react-tags.css";

class ProfileEdit extends Component {

  constructor (props) {
    super(props)

    this.state = {
      profile: null,
      username: "",
      email: "",

      videos: [],
      instruments:[],
      tags: [],
      suggestions: [
        { name: "Piano" },
        { name: "Violin" },
        { name: "Voice" },
        { name: "Cello" },
        { name: "Oboe" },
        { name: "Flute" }
      ],
    }
  }

  componentDidMount() {
    if (this.props.auth.isAuthenticated === false) {
      this.props.history.push("/");
      return
    } else {
      console.log("cognito: " + JSON.stringify(this.props.auth.user.attributes, null, 4));
      console.log("profile: " + JSON.stringify(this.props.auth.profile, null, 4));
      this.getUserProfile();
    }
  }

  getUserProfile = async () => {
    console.log("[getUserProfile]");
    const ApiUrl = "https://szo8d8uv4a.execute-api.us-east-1.amazonaws.com/prod/service?username="+this.props.auth.user.username;

    return fetch(ApiUrl, {
      method: "GET",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => response.json())

    .then((responseJson) => {
      console.log("responseJson: " + JSON.stringify(responseJson));

      const item = responseJson["body"].Item;
      console.log('item: ' + JSON.stringify(item));

      const instruments = (!item.instruments) ? this.state.tags : item.instruments;
      const videos = (!item.videos) ? this.state.videos : item.videos;
      console.log("instruments: " + instruments);
      this.setState({
        profile: item,
        username: item.username,
        email: item.email,
        tags: instruments,
        videos: videos,

      }, () => {
        // do something
      });
    })
    .catch((error) => {
      console.log("ERROR [getUserID]: " + JSON.stringify(error));
    });
  }


  handleDelete (i) {
    const tags = this.state.tags.slice(0)
    tags.splice(i, 1)
    this.setState({ tags })
  }

  handleAddition (tag) {
    const tags = [].concat(this.state.tags, tag)
    this.setState({ tags })
  }

  getAllInstruments = () => {
    var str = "(";
    for (var i = 0; i<this.state.suggestions.length; i++) {
      const instrument = this.state.suggestions[i];
      const endStr = (i === this.state.suggestions.length-1) ? ")" : ", ";
      str += instrument.name + endStr;
    }

    return str;
  }

  handleSaveProfile = () => {
    console.log("[handleSaveProfile] tags: " + JSON.stringify(this.state.tags));

    console.log("profile: " + JSON.stringify(this.props.auth.profile, null,4));
    console.log("videos: " + JSON.stringify(this.state.videos, null,4));
    console.log("instruments: " + JSON.stringify(this.state.tags, null,4));


    const ApiUrl = "https://szo8d8uv4a.execute-api.us-east-1.amazonaws.com/prod/service";

    let json = {
      "email": this.props.auth.profile.username,
      "instruments": this.state.tags,
      "firstname": this.props.auth.profile.firstname,
      "lastname": this.props.auth.profile.lastname,
      "videos": this.state.videos
    }

    return fetch(ApiUrl, {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(json)
    })
    .then((response) => response.json())

    .then((responseJson) => {
      console.log("responseJson: " + JSON.stringify(responseJson));
      const body = responseJson["body"];
      let list = body.Items;
      console.log('list: ' + JSON.stringify(list));
      this.setState({
        //usersList: list,
      }, () => {
      // TESTIMNG ======

      // ===============
      });
      this.props.history.push("/profileview")
    })
    .catch((error) => {
      console.log("ERROR: " + JSON.stringify(error))
    });
  }

  render() {
    return (
      <div>
      <Navbar auth={this.props.auth}/>
        <div className="container">
          <div className="profile-section-block">
            <h1>Edit Profile</h1>
          </div>
          <div className="profile-section-block">
            <h3>User Name</h3>
            <div className="text-display">
              {this.props.auth.profile.firstname} {this.props.auth.profile.lastname}
            </div>
          </div>
          <div className="profile-section-block">
            <h3>Instruments { this.getAllInstruments() }</h3>
            <div className="text-display">
            <ReactTags className="react-tags"
              placeholder="Add Your Instrument"
              tags={this.state.tags}
              suggestions={this.state.suggestions}
              handleDelete={this.handleDelete.bind(this)}
              handleAddition={this.handleAddition.bind(this)} />
            </div>
          </div>
          <div className="profile-section-block">
            <div className="buttons">
              <a href="#" className="button is-primary" onClick={this.handleSaveProfile}>
                <strong>Save Profile</strong>
              </a>
              <a href="/profileview" className="button is-primary">
                <strong>View Profile</strong>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProfileEdit;
