import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import Amplify, { Auth, Storage } from "aws-amplify";
import InfiniteScroll from 'react-infinite-scroll-component';
import VideoListCell from './VideoListCell';

class MasterclassListMain extends Component {

  constructor(props) {
    super(props);
    this.state = {
      profile: null,
    }
  }

  componentDidMount() {

  }

  fetchMoreData = () => {
    // a fake async api call like which sends
    // 20 more records in 1.5 secs
    /*
    setTimeout(() => {
      this.setState({
        items: this.state.items.concat(Array.from({ length: 20 }))
      });
    }, 1500);

    */
  };

  masterclassSelected = async event => {
    console.log("[masterclassSelected] classID: " + event.target.id);
    //this.props.parent.loadVideo(fileID);
  }

  getItems = () => {
    console.log('[getItems]');

    const list = this.props.items;
    if (list === null || list === undefined) {
      return <div> Loading ... </div>;
    }

    const itemsList = list.map((item, index) =>
      <div className="masterclass-item" key={index}>
        <div className="masterclass-pic">{item.master}</div>
        <div className="masterclass-overview">
          <div className="masterclass-overview title">{item.title}</div>
          <div className="masterclass-overview description">{item.description}</div>
          <div className="masterclass-overview description">instrument: {item.categories[0].name}</div>
          <div className="masterclass-overview description">{item.videos.length} videos</div>
          <div className="buttons">
            <div className="button is-primary" id={item.classID} onClick={this.masterclassSelected}> View Masterclass</div>
          </div>
        </div>
      </div>
    )
    return (
    <div>
      {itemsList}
    </div>
    )

  }

  render() {
    return(
      <div>
        <InfiniteScroll
          dataLength={this.props.length}
          next={this.fetchMoreData}
          hasMore={true}
          //loader={<h4>{(this.props.items.length === 0) ? "the list is empty." : "loading..."}</h4>}
          height={this.props.height}
        >
          {this.getItems()}
        </InfiniteScroll>
      </div>
    )
  }
}

export default withRouter(MasterclassListMain);

/*
<VideoListCell videoList={this} auth={this.props.auth} item={i} key={index} />
*/
