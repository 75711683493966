import React, { Component }  from 'react';
import { withRouter } from "react-router-dom";
import Amplify, { Auth, Storage } from "aws-amplify";
import { Player } from 'video-react';
import VideoList from "../video/VideoList";
import Navbar from '../Navbar';
import VideoListContainer from "../video/VideoListContainer";
import "../../../node_modules/video-react/dist/video-react.css";
import "./video.css";

//identityPoolId: 'us-east-1:d82d1e7f-d8ad-4d3c-a650-bf48ec638361', //REQUIRED - Amazon Cognito Identity Pool ID

Amplify.configure({
    Auth: {
        identityPoolId: 'us-east-1:50d2efc8-5946-4d81-bebb-780171f49aa1', //REQUIRED - Amazon Cognito Identity Pool ID
        region: 'us-east-1', // REQUIRED - Amazon Cognito Region
    },
    Storage: {
        AWSS3: {
            bucket: 'rcm-master-class-videos', //REQUIRED -  Amazon S3 bucket
            region: 'us-east-1',
            level: 'private'
        }
    }
});

class WatchVideo extends Component {

  constructor(props) {
    super(props);
    this.state = {
      profile: {
        videos: [],
        instruments: [],
        username:null,
        email:null,
        id: null,
      },
      showAddToListButton: true,
      height:0,
      fileID:"Level-6-Wk-8-Theory-Webinar",
      placeholder: null,
      videoFile: null,
      title: "loading",
      description: "loading"
    }
  }

  componentDidMount() {
    if (this.props.auth.isAuthenticated === false) {
      this.props.history.push("/");
      return;
    }

    const id = this.props.auth.selectedVideoID;
    console.log("setSelectedVideoID in WatchVideo: " + id);

    setTimeout(() => {
      const height = this.divElement.clientHeight;
      this.setState({ height: height });
    }, 1)
  }

  setWindowHight = () => {
    const windowWidth = window.innerWidth;
    const windowHeight = windowWidth * (16 / 9);
  }

  setVideo = async event => {
    await this.getVideoData();
    this.checkFavourites();
    await this.getUserData();
    this.getPlaceHolderImage(this.state.placeholder);
    this.getVideoFile(this.state.videoFile);
  }

  getVideoData = ID => {
    console.log("[getVideoData] fileID: " + this.state.fileID);
    const ApiUrl = "https://ljnrpe322d.execute-api.us-east-1.amazonaws.com/prod/service?fileID="+this.state.fileID;

    return fetch(ApiUrl, {
      method: "GET",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => response.json())

    .then((responseJson) => {
      //console.log("responseJson: " + JSON.stringify(responseJson));

      const item = responseJson["body"].Item;
      //console.log('item: ' + JSON.stringify(item));

      this.setState({
        placeholder: item.poster,
        videoFile: item.file,
        title: item.title,
        description: item.description
      }, () => {
        // do something
      });

    })
    .catch((error) => {
      console.log("ERROR [getVideoData]: " + JSON.stringify(error));
    });
  }

  getPlaceHolderImage = fileName => {
    console.log("[getPlaceHolderImage] fileName: " + fileName + " current user is " + JSON.stringify(this.props.auth.user.pool.userPoolId));
    Storage.get(fileName, {
    identityId: this.props.auth.user.pool.userPoolId // the identityId of that user
    })
    .then(result =>
      this.setState({
        placeholder: result
      })
    )
    .catch(err => console.log("error retrieving placeholder: " + err));
  }

  getVideoFile = fileName => {
    console.log("[getVideoFile] fileName: " + fileName + " current user is " + JSON.stringify(this.props.auth.user.pool.userPoolId));
    Storage.get(fileName, {
    identityId: this.props.auth.user.pool.userPoolId // the identityId of that user
    })
    .then(result =>

      this.setState({
        videoFile: result
      })
    )
    .catch(err => console.log("error retrieving videoFile: " + err));
  }

  checkFavourites = () => {
    console.log("[checkFavourites] list: " + this.state.profile.videos + " has " + this.state.profile.videos.length);

    const arr = this.state.profile.videos;
    for (var i = 0; i < arr.length; i++) {
      let id = arr[i];
      //console.log("id: " + id + " fileID: " + this.state.fileID);
      if (id === this.state.fileID) {
        this.setState({
          showAddToListButton: false
        }, ()=> {

        });
        return;
      } else {
        this.setState({
          showAddToListButton: true
        });
      }
    }
  }

  getUserData = async event => {
    console.log("[getUserData]");

    await this.props.auth.setUserProfile();
    const data = await this.props.auth.getUserProfile();

    //console.log("data: " + JSON.stringify(data));

    this.setState({
      profile: data
    }, () => {
      this.checkFavourites()
    });


    /*
    const ApiUrl = "https://szo8d8uv4a.execute-api.us-east-1.amazonaws.com/prod/service?username="+this.props.auth.user.username;

    return fetch(ApiUrl, {
      method: "GET",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => response.json())

    .then((responseJson) => {
      console.log("responseJson [getUserData]: " + JSON.stringify(responseJson));
      const item = responseJson["body"].Item;
      const videoList = item.videos;
      const videos = (videoList) ? videoList : this.state.user.videos;
      const instruments = (item.instruments) ? item.instruments : this.state.user.instruments;
      console.log("videoList: " + videoList);
      if (videoList !== undefined) {
        this.setState({
          profile: {
            username:item.username,
            email: item.email,
            videos: videos,
            instruments:instruments,
            id: item.id
          }
        }, () => {
          this.checkFavourites()
        });
      }
    })
    .catch((error) => {
      console.log("ERROR [getUserData]: " + JSON.stringify(error));
    });

    */
  }

  handleAddToList = () => {
    console.log("[handleAddToList] profile: " + JSON.stringify(this.state.profile));

    var arr = this.state.profile.videos;
    arr.push(this.state.fileID);

    const videos = arr;
    console.log("videos to be added: " + JSON.stringify(videos, null, 4));
    let json = {
      "videos": videos
    }
    this.props.auth.updateUserProfile(json);

    this.setState({
      showAddToListButton: false,
    });

  }

  arrayRemove = (arr, value) => {
     return arr.filter(function(ele){
         return ele != value;
     });
  }

  handleRemoveFromList = () => {
    console.log("[handleRemoveFromList] profile: " + JSON.stringify(this.state.fileID));

    var arr = this.arrayRemove(this.state.profile.videos, this.state.fileID);

    const videos = arr;

    let json = {
      "videos": videos
    }

    console.log("json: " + JSON.stringify(json));

    this.props.auth.updateUserProfile(json);

    this.setState({
      showAddToListButton: true,
    });

  }


  render() {
    //this.getPlaceHolderImage("Level-6-Wk-8-Theory-Webinar.png");
    return (
      <div>
      <Navbar auth={this.props.auth}/>
        <div className="video-page-container" ref={ (divElement) => this.divElement = divElement}>
          <div className="video-page-left">
            <div className="video-player">
              <Player
                playsInline
                poster = {this.state.placeholder}
                src={this.state.videoFile}
              />
              <div className="video-title"> {this.state.title} </div>
              <div className="video-description"> {this.state.description} </div>
              <div className="video-options">
                <div className="buttons">
                {this.state.showAddToListButton  && (
                  <div className="button is-primary" onClick={this.handleAddToList}>
                    <strong>Add to my list</strong>
                  </div>
                  )}

                  {!this.state.showAddToListButton  && (
                    <div className="button is-primary" onClick={this.handleRemoveFromList}>
                      <strong>Remove from my list</strong>
                    </div>
                    )}

                </div>
              </div>
            </div>
          </div>
          <div className="video-page-right">
            <div className="video-list">
              <VideoListContainer videoView={this} auth={this.props.auth} height={this.state.height} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(WatchVideo);

/*

<div className="video-area">
  <div className="video-player">
    <Player
      playsInline
      poster = {this.state.placeholder}
      src={this.state.videoFile}
    />
    <div className="video-title"> {this.state.title} </div>
  </div>
  <div className="video-list">
    <VideoList />
  </div>
</div>

*/
