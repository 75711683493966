import React from 'react'

export default function HomeContent() {
  return (
    <section className="container">
        <div className="columns features">

        </div>
    </section>
  )
}
