import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import MasterclassListMain from "./MasterclassListMain";

class MasterclassListContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      profile: null,
      fileID:null,
      hight: 0,
      items: (this.props.items) ? this.props.items: [],
    };
  };

  componentDidMount() {
    console.log("this.props.height in VideoListContainer: " + this.props.height);
    this.setState({
      height: this.props.height,
    });

    /*
    if (this.props.auth.selectedVideoID !== null) {
      this.setState({
        fileID: this.props.auth.selectedVideoID
      }, () => {
        this.props.auth.setSelectedVideoID(null);
        this.setVideoList();
      });
    } else {
      this.setVideoList();
    }
    */
  }

/*
  setVideoList = async event => {
    console.log("[setVideoList]");

    const profile = this.props.auth.getUserProfile();
    if (profile.username === null) {
      await this.props.auth.setUserProfile();
    }
    this.setState({
      profile: this.props.auth.getUserProfile()
    }, () => {
      //this.checkUsername()
      this.getVideoList()
    })
  }
*/

/*
  getVideoList = () => {
    console.log("[getVideoList]");
    const ApiUrl = "https://ljnrpe322d.execute-api.us-east-1.amazonaws.com/prod/service";

    return fetch(ApiUrl, {
      method: "GET",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => response.json())

    .then((responseJson) => {
      console.log("responseJson: " + JSON.stringify(responseJson));

      var items = responseJson["body"].Items;
      console.log('items: ' + JSON.stringify(items));

      this.setState({
        items: items
      }, () => {

        if (this.state.fileID) {
          this.props.videoView.setState({
            fileID: this.state.fileID
          }, () => {
            this.props.videoView.setVideo();
          });
        } else {
          this.props.videoView.setState({
            fileID: items[0].fileID
          }, () => {
            this.props.videoView.setVideo();
          });
        }

      });
    })
    .catch((error) => {
      console.log("ERROR [getUserID]: " + JSON.stringify(error));
    });
  }
*/


  loadVideo = fileID => {
    console.log("[loadVideo] fileID: " + fileID);

    this.props.videoView.setState({
      fileID: fileID
    }, ()=> {
      this.props.videoView.setVideo()
    });

    /*
    if (this.props.videoView) {

    } else {
      // OPEN WatchVideo view
      console.log("OPEN WatchVideo with " + fileID);
      await this.props.auth.setSelectedVideoID(fileID);
      this.props.history.push("/watchvideo");
    }
    */
  }

  render() {
    return(
      <div className="masterclass-list-container">
        <MasterclassListMain parent={this} items={this.props.items} auth={this.props.auth} height={this.props.height} />
      </div>
    )}
}
export default withRouter(MasterclassListContainer);

/* <MasterclassListMain parent={this} items={this.state.items} auth={this.props.auth} height={this.props.height} /> */
