import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { Auth } from "aws-amplify";
import { Container, Row, Col } from 'react-bootstrap';
import RCMlogo from "../assets/images/rcm-logo.svg";
import MenuIcon from "../assets/images/icon_menu.svg";
import UserIcon from "../assets/images/icon_user.svg";
import "./Navbar.css";

class Navbar extends Component {

  componentWillMount() {
    // console.log("this.props.globalVars.profilePopup: " + this.props.auth.globalVars.profilePopup);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClick);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick);
  }


  handleClick = e => {
    //console.log("[handleClick] " + e.target);

    if (e.target != this.profileNode) {
      //console.log("clicked outside profileNode");
      this.props.auth.updateProfilePopup(false);
      return;
    }

  };

  profilePopup = event => {
    //console.log("[profilePopup]");
    this.props.auth.updateProfilePopup(true);
  }


  handleLogOut = async event => {
    //console.log("[handleLogOut]");
    event.preventDefault();
    try {
      await Auth.signOut();
      this.props.auth.setAuthStatus(false);
      this.props.auth.setUser(null);
      this.props.history.push("/");
    }catch(error) {
      // console.log("error loging out: " + error.message);
    }
  }

  render() {
    return (
      <Container>
        <Row>
          <Col>
          <div className="navbar-menu">

          {this.props.auth.isAuthenticated && this.props.auth.user && this.props.auth.user.attributes.email == "tak.yamada@rcmusic.ca" && (
            <div className="navbar-start">
              <a href="/" className="navbar-item">
                Home
              </a>
              <a href="/watchvideo" className="navbar-item">
                Watch Videos
              </a>

              <a href="/profileview" className="navbar-item">
                View Profile
              </a>
            </div>
            )}
          </div>
          </Col>
          <Col xs={{ order: 2 }}>
            <div className="navbar-brand">
              <img src={RCMlogo} width="120" height="28" alt="rcm logo" />
            </div>
          </Col>
          <Col xs={{ order: 3 }}>
            {this.props.auth.isAuthenticated && this.props.auth.user && (
              <div className="navbar-profile" onClick={ this.profilePopup }>
                  <div className="username">
                    Hello {this.props.auth.profile.firstname} {this.props.auth.profile.lastname}
                  </div>
                  <div className="profile-icon">
                  <a>
                    <img src={UserIcon} width="24" height="24" alt="profile" />
                  </a>
                  </div>
                  {this.props.auth.globalVars.profilePopup &&
                    <a ref={ (profileNode) => this.profileNode = profileNode} className="profile-popup" onClick={ this.handleLogOut }>Log Out</a>
                  }
                </div>
              )}
          </Col>
        </Row>
      </Container>


    )
  }
}

export default withRouter(Navbar);
/*



<img src={MenuIcon} width="24" height="24" alt="menu" />

<div className="buttons">

{this.props.auth.isAuthenticated && (
  <a href="/login" onClick={ this.handleLogOut } className="button is-light">
    Log out
  </a>
)}

</div>

<a href="/masterclass" className="navbar-item">
  Find a masterclass
</a>

<a href="/products" className="navbar-item">
  Products
</a>
<a href="/admin" className="navbar-item">
  Admin
</a>

<a href="/login" className="button is-light">
  Log in
</a>
*/
