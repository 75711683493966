import React, { Component, Fragment } from 'react';
import Chevron from "../../../assets/images/chevron.svg";
import "./InstrumentPicker.css";
import "./PitchPicker.css";
import { Button } from 'react-bootstrap';

class PitchPicker extends Component  {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (this.props.auth.isAuthenticated === false) {
      this.props.history.push("/");
    }

  }

  handleSelectPitch(event) {
    // console.log("[handleSelectPitch]");
    const target = event.target;
    const name = target.id;

    // console.log("target: " + target + " name: " + name + " converter: " + this.props.converter);


    this.props.converter.setState({
      pitch: name,
        }, () =>
        this.updateConverterPage()
    );
  }

  updateConverterPage() {
    this.props.converter.setState({
      currPage: "transpositions",
    });
  }

  goBackToInstrumentPicker(event) {
    // console.log("this.props.converter.currPage: " + this.props.converter.currPage);
    this.props.converter.setState({
      currPage: "instruments",
    });
  }

  getFontClass(str){
    //console.log("[getFontClass] str: " + str);
    let style = (str === "#" || str === "b") ? "dlmbold" : "";
    return style;
  }

  checkUpperCase(str) {

    if (str === "#" || str === "b"){
      return str.toLowerCase();
    } else {
      return str.toUpperCase();
    }
  }

  getText(text) {
    //console.log("[getText] text: " + text);
    //let newText = this.props.converter.props.main.checkForMusicSymbol(text);
    //console.log("newText: " + newText);
    var strArr = [];

    for (let i in text) {
      var str = text[i];
      //console.log("str: " + str);
      strArr.push(str);
    }
    var newText = strArr.map((str, index) =>
      <span id={text.replace(/\s/g, '')} key={index} className={this.getFontClass(str)}>{this.checkUpperCase(str)}</span>
    );
    //console.log("newText: " + newText);
    return (
      <div id={text.replace(/\s/g, '')}>{newText}</div>
    );
  }

  getTranspositions() {
    let notes = [
      {id:"C", text: "C"},
      {id:"C#/Db", text: "C# / Db"},
      {id:"D", text: "D"},
      {id:"D#/Eb", text: "D# / Eb"},
      {id:"E", text: "E"},
      {id:"F", text: "F"},
      {id:"F#/Gb", text: "F# / Gb"},
      {id:"G", text: "G"},
      {id:"G#/Ab", text: "G# / Ab"},
      {id:"A", text: "A"},
      {id:"A#/Bb", text: "A# / Bb"},
      {id:"B", text: "B"},

    ];
    let buttons = notes.map((note, index) =>
      <Button key={index} variant="outline-info" id={note.id} className="converter-button pitch-button  animated pulse" onClick={(event)=>this.handleSelectPitch(event)}>{this.getText(note.text)}</Button>
    );

    return (
      <div className="instruments-buttons">{buttons}</div>
    );
  }

  getInstrumentPitch(note) {

  }

  render() {
    return (
        <Fragment>
          <div className="justify-content-md-center">
            <div className="tool-title">TRANSPOSITION GENERATOR</div>
            <div className="pitch-title-container animated flipInX">
              <div className="page-title-small">Select Your</div>
              <div className="page-title-large">Pitch for Transposition</div>
            </div>
            <div className="compare-area  animated fadeInDown">
              <div className="compare-area-left-text">
                {this.getText(this.props.converter.state.originalInstrument)}
              </div>
              <div className="compare-area-center">
                <img className="middle-arrow-transposition" src={Chevron} width="48" height="48" alt="arrow" />
              </div>
              <div className="compare-area-right">
                <div className="converter-underline underline-highlight animated pulse" />
              </div>
            </div>

            <div className="pitch-buttons-container animated fadeInUp">
              {this.getTranspositions()}
            </div>
          </div>
        </Fragment>
      )}
}

export default PitchPicker;
