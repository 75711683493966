import React, { Component } from 'react';
import { Container, Row, Col} from 'react-bootstrap';
import LandingPageHeader from "../LandingPageHeader";
import "./Welcome.css";

class Welcome extends Component {

  handleLogIn = () => {
    console.log("[handleLogIn]");
    this.props.history.push('/login');
  }
  render() {
    return (
      <div className="container">
        <LandingPageHeader />
        <div className="landing-main">
          <div className="landing-title">Welcome</div>
        </div>
        <Container>
          <Row className="justify-content-md-center">
            <Col className="auth-col">
              <div className="auth-text">
                <p>We've sent you a confirmation email.  Please click on the confirmation link to verify your account.
                Once verified your email address, please log in <a onClick={this.handleLogIn}>HERE</a>.</p>
              </div>
              <div className="spacer-bottom" />
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

export default Welcome;
