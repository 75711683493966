import React, { Component } from "react";
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import LandingPageHeader from "../LandingPageHeader";
class ChangePasswordConfirmation extends Component {
  render() {
    return (
      <div className="container">
        <LandingPageHeader />

        <div className="landing-main">
          <div className="landing-title">Change Password</div>
        </div>

        <Container>
          <Row className="justify-content-md-center">
            <Col className="auth-col">
              <div className="auth-text text-center">
                <p>Your password has been successfully updated!</p>
              </div>
              <div className="spacer-bottom" />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default ChangePasswordConfirmation;
