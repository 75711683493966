import React, { Component } from 'react';
import Amplify, { Auth, Storage } from "aws-amplify";

// identityPoolId: 'us-east-1:d82d1e7f-d8ad-4d3c-a650-bf48ec638361', //REQUIRED - Amazon Cognito Identity Pool ID
Amplify.configure({
    Auth: {
        identityPoolId: 'us-east-1:50d2efc8-5946-4d81-bebb-780171f49aa1', //REQUIRED - Amazon Cognito Identity Pool ID
        region: 'us-east-1', // REQUIRED - Amazon Cognito Region
    },
    Storage: {
        AWSS3: {
            bucket: 'rcm-master-class-videos', //REQUIRED -  Amazon S3 bucket
            region: 'us-east-1',
            level: 'private'
        }
    }
});

class VideoListCell extends Component {

  constructor(props) {
    super(props);
    this.state = {
      thumb: null,
    }
  }

  componentDidMount() {
    //console.log("item is " + JSON.stringify(this.props.item));
    this.getThumbnail(this.props.item.thumb);
  }

  handleSelectVideo = () => {
    //console.log("[handleSelectVideo] ID: " + this.props.item.fileID);
    this.props.videoList.videoSelected(this.props.item.fileID);
  }

  getThumbnail = fileName => {
    //this.props.item.thumb
    console.log("[getThumbnail] current user is " + JSON.stringify(this.props.auth.user.pool.userPoolId));
    Storage.get(fileName, {
    identityId: this.props.auth.user.pool.userPoolId // the identityId of that user
    })
    .then(result =>
      this.setState({
        thumb: result
      }
    ))
    .catch(err => console.log("error retrieving thumbnail: " + err));
  }

  render() {
    return(
      <div className="video-list-cell" onClick={ this.handleSelectVideo }>
        <div className="video-thumb">
          <img src={(this.state.thumb === null) ? "loading thumbnail" : this.state.thumb} alt="thumb" />
        </div>
        <div className="video-cell-title"> {this.props.item.title} </div>
        <div className="video-cell-description"> {this.props.item.description} </div>
      </div>
    )
  }
}
export default VideoListCell;
