import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import VideoListMain from "./VideoListMain";

class VideoListContainerProfile extends Component {

  constructor(props) {
    super(props);
    this.state = {
      profile: null,
      fileID:null,
      hight: 0,
      items: [],
    };
  };

  componentDidMount() {
    if (this.props.auth.isAuthenticated === false) {
      return;
    } else {
      this.setState({
        height: this.props.height,
      });
      this.setVideoList();
    }


  }

  setVideoList = async event => {
    console.log("[setVideoList]");

    const profile = this.props.auth.getUserProfile();
    if (profile.username === null) {
      await this.props.auth.setUserProfile();
    }
    this.setState({
      profile: this.props.auth.getUserProfile()
    }, () => {
      //this.checkUsername()
      this.getVideoList()
    })
  }

  getVideoList = () => {
    console.log("[getVideoList]");
    const ApiUrl = "https://ljnrpe322d.execute-api.us-east-1.amazonaws.com/prod/service";

    return fetch(ApiUrl, {
      method: "GET",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => response.json())

    .then((responseJson) => {
      //console.log("responseJson: " + JSON.stringify(responseJson));

      var items = responseJson["body"].Items;
      //console.log('items: ' + JSON.stringify(items));


      // filter out videos not in the presetList
      if (this.props.presetList && this.props.presetList.length > 0) {
        //console.log("this.props.presetList: " + this.props.presetList + " and the length is " + this.props.presetList.length);
        const allItems = items;
        var newItems = [];
        for (var i = 0; i<this.props.presetList.length; i++) {
          const item = this.props.presetList[i];
          for (var j = 0; j<allItems.length; j++) {
            const userItem = allItems[j];
            console.log("item: " + item + " userItem: " + userItem.fileID);
            if (userItem.fileID === item) {
              newItems.push(userItem);
            }
          }
        }
        items = newItems;
        //console.log("items: " + JSON.stringify(items));
      } else {
        this.setState({
          loaderText: "The list is currently empty."
        })
      }

      this.setState({
        items: items
      }, () => {

        /*
        if (this.props.videoView !== null) {
          if (this.state.fileID) {
            this.props.videoView.setState({
              fileID: this.state.fileID
            }, () => {
              this.props.videoView.setVideo();
            });
          } else {
            this.props.videoView.setState({
              fileID: items[0].fileID
            }, () => {
              this.props.videoView.setVideo();
            });
          }
        }

        */
      });
    })
    .catch((error) => {
      console.log("ERROR [getUserID]: " + JSON.stringify(error));
    });
  }

  loadVideo = async fileID => {
    console.log("[loadVideo] fileID: " + fileID);

    // OPEN WatchVideo view
    console.log("OPEN WatchVideo with " + fileID);

    await this.props.auth.setSelectedVideoID(fileID);
    this.props.history.push("/watchvideo");

    /*
    if (this.props.videoView) {
      this.props.videoView.setState({
        fileID: fileID
      }, ()=> {
        this.props.videoView.setVideo()
      });
    } else {

    }
    */
  }


  render() {
    return(
      <div className="video-list-container">
        <VideoListMain parent={this} items={this.state.items} auth={this.props.auth} height={this.props.height} />
      </div>
    )}
}

export default withRouter(VideoListContainerProfile);
