import React, { Component }  from 'react';
import { withRouter } from "react-router-dom";
import Amplify, { Auth, Storage } from "aws-amplify";
import { Player } from 'video-react';
import VideoList from "../video/VideoList";
import MasterclassListContainer from "../video/MasterclassListContainer";
import "../../../node_modules/video-react/dist/video-react.css";
import "./masterclass.css";

Amplify.configure({
    Auth: {
        identityPoolId: 'us-east-1:d82d1e7f-d8ad-4d3c-a650-bf48ec638361', //REQUIRED - Amazon Cognito Identity Pool ID
        region: 'us-east-1', // REQUIRED - Amazon Cognito Region
    },
    Storage: {
        AWSS3: {
            bucket: 'rcm-master-class-videos', //REQUIRED -  Amazon S3 bucket
            region: 'us-east-1',
        }
    }
});

class Masterclass extends Component {

  constructor(props) {
    super(props);
    this.state = {
      height:0,
      masterclassList: null,
      masterclassInstruments: [],
      category:"All",
    }
  }

  componentDidMount() {
    if (this.props.auth.isAuthenticated === false) {
      this.props.history.push("/");
      return;
    }

    setTimeout(() => {
      const height = this.divElement.clientHeight;
      this.setState({ height: height });
    }, 1)

    this.setMasterclass();
  }

  setMasterclass = async event => {
    await this.getMasterclassData();
    //await this.getUserData();
    //this.getPlaceHolderImage(this.state.placeholder);
    //this.getVideoFile(this.state.videoFile);
  }

  getMasterclassData = () => {
    console.log("[getMasterclassData]");
    const ApiUrl = "https://z5enmxnzn5.execute-api.us-east-1.amazonaws.com/prod/service";

    return fetch(ApiUrl, {
      method: "GET",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => response.json())

    .then((responseJson) => {
      //console.log("responseJson: " + JSON.stringify(responseJson));

      const items = responseJson["body"].Items;
      console.log('items: ' + JSON.stringify(items));

      this.setState({
        masterclassList:items,
      }, () => {
        // do something
      });

    })
    .catch((error) => {
      console.log("ERROR [getMasterclassData]: " + JSON.stringify(error));
    });
  }


  /*
  onInputChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
    document.getElementById(event.target.id).classList.remove("is-danger");
  }
  */

  getMasterclasses = () => {
    console.log("[getMasterclasses]");
    const list = this.state.masterclassList;
    if (list === null) {
      return;
    }

    if (this.state.category === "All") {
      return list;
    }

    var currArr = [];
    for (var i = 0; i<list.length; i++) {
      const masterclass = list[i];
      const category = masterclass.categories;
      if (category[0].name === this.state.category) {
        currArr.push(masterclass);
      }
    }
    return currArr;
  }

  getMasterclassInstruments = () => {
    console.log("[getMasterclassInstruments]");

    const list = this.state.masterclassList;
    if (list === null) {
      return;
    }
    console.log("sort mastercassList");

    const listItems = list.map((item,i) =>
       <div onClick={this.handleSelectInstrument} id={item.categories[0].name} className="masterclass-instrument" key={i}>{item.categories[0].name}</div>
    )

    return (
    <div>
      {listItems}
    </div>
    );
  }

  handleSelectInstrument = event => {
    console.log("[handleSelectInstrument] instrument: " + event.target.id);
    this.setState({
      category: event.target.id
    });
  }

  getPlaceHolderImage = fileName => {
    console.log("[getPlaceHolderImage] fileName: " + fileName + " current user is " + JSON.stringify(this.props.auth.user.pool.userPoolId));
    Storage.get(fileName, {
    identityId: this.props.auth.user.pool.userPoolId // the identityId of that user
    })
    .then(result =>
      this.setState({
        placeholder: result
      })
    )
    .catch(err => console.log("error retrieving placeholder: " + err));
  }

  getVideoFile = fileName => {
    console.log("[getVideoFile] fileName: " + fileName + " current user is " + JSON.stringify(this.props.auth.user.pool.userPoolId));
    Storage.get(fileName, {
    identityId: this.props.auth.user.pool.userPoolId // the identityId of that user
    })
    .then(result =>

      this.setState({
        videoFile: result
      })
    )
    .catch(err => console.log("error retrieving videoFile: " + err));
  }

  checkFavourites = () => {
    console.log("[checkFavourites] list: " + this.state.profile.videos + " has " + this.state.profile.videos.length);

    const arr = this.state.profile.videos;
    for (var i = 0; i < arr.length; i++) {
      let id = arr[i];
      console.log("id: " + id + " fileID: " + this.state.fileID);
      if (id === this.state.fileID) {
        this.setState({
          showAddToListButton: false
        }, ()=> {

        });
        return;
      } else {
        this.setState({
          showAddToListButton: true
        });
      }
    }
  }

  getUserData = async event => {
    console.log("[getUserData]");

    await this.props.auth.setUserProfile();
    const data = await this.props.auth.getUserProfile();

    console.log("data: " + JSON.stringify(data));

    this.setState({
      profile: data
    }, () => {
      this.checkFavourites()
    });


    /*
    const ApiUrl = "https://szo8d8uv4a.execute-api.us-east-1.amazonaws.com/prod/service?username="+this.props.auth.user.username;

    return fetch(ApiUrl, {
      method: "GET",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => response.json())

    .then((responseJson) => {
      console.log("responseJson [getUserData]: " + JSON.stringify(responseJson));
      const item = responseJson["body"].Item;
      const videoList = item.videos;
      const videos = (videoList) ? videoList : this.state.user.videos;
      const instruments = (item.instruments) ? item.instruments : this.state.user.instruments;
      console.log("videoList: " + videoList);
      if (videoList !== undefined) {
        this.setState({
          profile: {
            username:item.username,
            email: item.email,
            videos: videos,
            instruments:instruments,
            id: item.id
          }
        }, () => {
          this.checkFavourites()
        });
      }
    })
    .catch((error) => {
      console.log("ERROR [getUserData]: " + JSON.stringify(error));
    });

    */
  }

  handleAddToList = () => {
    console.log("[handleAddToList] profile: " + JSON.stringify(this.state.profile));

    var arr = this.state.profile.videos;
    arr.push(this.state.fileID);

    const videos = arr;

    let json = {
      "videos": videos
    }
    this.props.auth.updateUserProfile(json);

    this.setState({
      showAddToListButton: false,
    });
  }
  arrayRemove = (arr, value) => {
     return arr.filter(function(ele){
         return ele != value;
     });
  }
  handleRemoveFromList = () => {
    console.log("[handleRemoveFromList] profile: " + JSON.stringify(this.state.fileID));

    var arr = this.arrayRemove(this.state.profile.videos, this.state.fileID);

    const videos = arr;

    let json = {
      "videos": videos
    }

    console.log("json: " + JSON.stringify(json));

    this.props.auth.updateUserProfile(json);

    this.setState({
      showAddToListButton: true,
    });

  }


  render() {
    //this.getPlaceHolderImage("Level-6-Wk-8-Theory-Webinar.png");
    return (

      <div className="masterclass-page-container" ref={ (divElement) => this.divElement = divElement}>
        <div className="masterclass-page-left">
          <div className="masterclass-instruments-list">
            <h2>Instruments</h2>
            <div className="masterclass-instruments">
              <div onClick={this.handleSelectInstrument} id="All" className="masterclass-instrument">All</div>
              { this.getMasterclassInstruments() }
            </div>
          </div>
        </div>
        <div className="masterclass-page-right">
          <div className="masterclass-list">

            <MasterclassListContainer items={ this.getMasterclasses() } videoView={this} auth={this.props.auth} height={this.state.height} />
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(Masterclass);

/*

<div className="video-area">
  <div className="video-player">
    <Player
      playsInline
      poster = {this.state.placeholder}
      src={this.state.videoFile}
    />
    <div className="video-title"> {this.state.title} </div>
  </div>
  <div className="video-list">
    <VideoList />
  </div>
</div>

*/
