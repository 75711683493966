import React, { Component } from 'react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import LandingPageHeader from "../LandingPageHeader";
import FormErrors from "../FormErrors";
import Validate from "../utility/FormValidation";
import { Auth } from 'aws-amplify';

class ForgotPasswordVerification extends Component {

  state = {
    verificationcode: "",
    email: "",
    newpassword: "",
    confirmpassword: "",
    errors: {
      cognito: null,
      blankfield: false
    }
  };

  clearErrorState = () => {
    this.setState({
      errors: {
        cognito: null,
        blankfield: false
      }
    });
  };

  passwordVerificationHandler = async event => {
    event.preventDefault();

    // compare Passwords

    if (this.state.newpassword != this.state.confirmpassword) {
      this.setState({
        errors: {
          apierrors: "Passwords don't match."
        }
      });
      return;
    }

    // Form validation
    this.clearErrorState();
    const error = Validate(event, this.state);
    if (error) {
      this.setState({
        errors: { ...this.state.errors, ...error }
      });
    }



    // AWS Cognito integration here
    try {
      await Auth.forgotPasswordSubmit(
        this.state.email,
        this.state.verificationcode,
        this.state.newpassword
      );
      this.props.history.push("/changepasswordconfirm");
    }catch(error) {
      console.log("error: " + error);
    }
  };

  onInputChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
    document.getElementById(event.target.id).classList.remove("is-danger");
  };


  handleLogin = () => {
    this.props.history.push("/login");
  }
  render() {
    return (
      <div className="container">
        <LandingPageHeader />

        <div className="landing-main">
          <div className="landing-title">Set new password</div>
        </div>

        <Container>
          <Row className="justify-content-md-center">
            <Col className="auth-col">
              <div className="auth-text text-center">
              <p>
              Please enter the verification code sent to your email address below,
              your email address and a new password.
              </p>
              </div>
            </Col>
          </Row>
        </Container>

        <Container className="form-container">
          <Row  className="justify-content-md-center">
            <Col className="form-field">
              <FormErrors formerrors={this.state.errors} apierrors={this.state.errors.apierrors}/>
            </Col>
          </Row>
          <Row  className="justify-content-md-center">
            <Col className="form-field">
              <div className="field-area">
                <div className="field-title">Enter verification code</div>

                <input
                  type="text"
                  className="form-input"
                  id="verificationcode"
                  aria-describedby="verificationCodeHelp"
                  value={this.state.verificationcode}
                  onChange={this.onInputChange}
                />
              </div>
            </Col>
          </Row>
          <Row  className="justify-content-md-center">
            <Col className="form-field">
              <div className="field-area">
                <div className="field-title">Enter your email</div>
                <input
                  className="form-input"
                  type="email"
                  id="email"
                  aria-describedby="emailHelp"
                  value={this.state.email}
                  onChange={this.onInputChange}
                />
              </div>
            </Col>
          </Row>
          <Row  className="justify-content-md-center">
            <Col className="form-field">
              <div className="field-area">
                <div className="field-title">New password</div>

                <input
                  type="password"
                  className="form-input"
                  id="newpassword"
                  value={this.state.newpassword}
                  onChange={this.onInputChange}
                />

              </div>
            </Col>
          </Row>
          <Row  className="justify-content-md-center">
            <Col className="form-field">
              <div className="field-area">
                <div className="field-title">Confirm  password</div>
                <input
                  type="password"
                  className="form-input"
                  id="confirmpassword"
                  value={this.state.confirmpassword}
                  onChange={this.onInputChange}
                />

              </div>
            </Col>
          </Row>

          <Row>
          <Col className="form-field">
            <Container className="register-buttons-area">
              <Row className="small-container justify-content-md-center">
                <Col className="field-left form-field input-small">
                  <div className="field-area input-small">
                    <div>
                      <Button id="submit" className="landing-button register-button button-left" onClick={ this.passwordVerificationHandler }>Submit</Button>
                    </div>
                  </div>
                </Col>
                <Col className="form-field input-small text-right">
                <div className="field-area input-small">
                  <div>
                    <a className="link button-right" onClick={this.handleLogin}>Log in</a>
                  </div>
                </div>
                </Col>
              </Row>
            </Container>
          </Col>
          </Row>
        </Container>
        </div>
    );
  }
}

export default ForgotPasswordVerification;
