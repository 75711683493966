
import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import ReactTags from 'react-tag-autocomplete';
import VideoList from '../video/VideoList';
import VideoListContainerProfile from '../video/VideoListContainerProfile';
import Navbar from '../Navbar';
import "../../react-tags.css";
import "./profile.css";


class ProfileView extends Component {

  constructor (props) {
    super(props)

    this.state = {
      height:0,
      profile: null,
      username: "",
      email: "",
      id: "",
      tags: [
      ],
      suggestions: [
        { name: "Piano" },
        { name: "Violin" },
        { name: "Voice" },
        { name: "Cello" },
        { name: "Oboe" },
        { name: "Flute" }
      ]
    }
  }

  componentDidMount() {
    console.log("[componentDidMount] ProfileView");
    if (this.props.auth.isAuthenticated === false) {
      this.props.history.push("/");
      return;
    } else {
      console.log("username: " + JSON.stringify(this.props.auth.user.attributes, null, 4));
      this.getUserProfile();
    }

    setTimeout(() => {
      const height = this.divElement.clientHeight;
      this.setState({ height: height });
    }, 1)
  }

  getUserProfile = async () => {
    console.log("[getUserProfile]");
    const ApiUrl = "https://szo8d8uv4a.execute-api.us-east-1.amazonaws.com/prod/service?username="+this.props.auth.user.attributes.email;

    return fetch(ApiUrl, {
      method: "GET",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => response.json())

    .then((responseJson) => {
      console.log("responseJson: " + JSON.stringify(responseJson));

      const item = responseJson["body"].Item;
      console.log('item: ' + JSON.stringify(item));

      const instruments = (!item.instruments) ? [] : item.instruments;
      console.log("instruments: " + JSON.stringify(instruments));
      this.setState({
        profile: item,
        username: item.username,
        email: item.email,
      }, () => {
        // do something
      });

    })
    .catch((error) => {
      console.log("ERROR [getUserID]: " + JSON.stringify(error));
    });
  }

  getInstruments = () => {
    console.log("[getInstruments]");
    var str = "";
    if (!this.state.profile) {
      return;
    }
    const instruments = this.state.profile.instruments;
    if (instruments === undefined) {
      return;
    }
    console.log("instruments: " + JSON.stringify(instruments));
    for (var i = 0; i<instruments.length; i++) {
      let instrument = instruments[i];
      let endStr = (i === instruments.length-1) ? "" : ", ";
      str += instrument.name + endStr;
    }
    return str;
  }

  getVideos = () => {
    console.log("[getVideos]");
    var str = "";
    if (!this.state.profile || !this.state.profile.videos) {
      return;
    }

    const videos = this.state.profile.videos;
    console.log("videos: " + JSON.stringify(videos));
    for (var i = 0; i<videos.length; i++) {
      let video = videos[i];
      let endStr = (i === videos.length-1) ? "" : ", ";
      str += video + endStr;
    }
    return str;
  }

  handleDelete (i) {
    const tags = this.state.tags.slice(0)
    tags.splice(i, 1)
    this.setState({ tags })
  }

  handleAddition (tag) {
    const tags = [].concat(this.state.tags, tag)
    this.setState({ tags })
  }

  render() {
    return (
      <div>
        <Navbar auth={this.props.auth}/>
        <div className="profile-page-container" ref={ (divElement) => this.divElement = divElement}>
          <div className="profile-page-left">
            <div className="profile-section-block">
              <h1>View Profile</h1>
            </div>
            <div className="profile-section-block">
              <h3>User Name</h3>
              <div className="text-display">
                {this.props.auth.profile.firstname} {this.props.auth.profile.lastname}
              </div>
            </div>
            <div className="profile-section-block">
              <h3>User Email</h3>
              <div className="text-display">
                {this.state.email}
              </div>
            </div>
            <div className="profile-section-block">
              <h3>Instruments</h3>
              <div className="text-display">
                {this.getInstruments()}
              </div>
            </div>
            <div className="profile-section-block">
              <div className="buttons">
                <a href="/profileedit" className="button is-primary">
                  <strong>Edit Profile</strong>
                </a>
              </div>
            </div>
          </div>

          <div className="profile-page-right">
          <div className="profile-video-list-title"> My Video List </div>
          <div className="video-list">
            <VideoListContainerProfile presetList={(this.state.profile) ? this.state.profile.videos: undefined} auth={this.props.auth} height={this.state.height} />
          </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ProfileView);
