import React, { Component, Fragment } from 'react';
import Chevron from "../../../assets/images/chevron.svg";
import "./InstrumentPicker.css";
import { Button } from 'react-bootstrap';
import Banner from "../../banner/Banner";


class InstrumentPicker extends Component  {

  constructor(props) {
    super(props);
    this.state = {
      height:0,
      originalInstrument: "concert",
      pitch: "C",
      currPage: "instruments"
    }
  }

  componentDidMount() {
    if (this.props.auth.isAuthenticated === false) {
      //this.props.history.push("/login");
      window.location.href = "/";
    }

    // test the coverter here
    // console.log("target: " + this.props.converter.props.main);
    //this.props.converter.props.main.checkForMusicSymbol("Bb");
  }

  handleSelectInstrument(event) {
    // console.log("[handleSelectInstrument]");
    const target = event.target;
    var name = target.id;
    if (name === "Concert") {
      name = "concert";
    }
    // console.log("target: " + target + " name: " + name + " converter: " + this.props.converter);


    this.props.converter.setState({
      originalInstrument: name,
        }, () =>
        this.updateConverterPage()
    );

  }

  updateConverterPage() {
    // console.log("originalInstrument in Converter " + this.props.converter.state.originalInstrument)
    this.props.converter.setState({
      currPage: "pitch",
    });
  }

  getFontClass(str){
    //console.log("[getFontClass] str: " + str);
    let style = (str === "#" || str === "b") ? "dlmbold" : "";
    return style;
  }

  getText(text) {
    //console.log("[getText] text: " + text);
    //let newText = this.props.converter.props.main.checkForMusicSymbol(text);
    //console.log("newText: " + newText);
    var strArr = [];

    for (let i in text) {
      let str = text[i];
      strArr.push(str);
    }
    var newText = strArr.map((str, index) =>
      <span id={text} key={index} className={this.getFontClass(str)}>{str}</span>
    );
    //console.log("newText: " + newText);
    return (
      <div id={text}>{newText}</div>
    );
  }

  render() {
    return (
        <Fragment>
          <div className="justify-content-md-center">
            <div className="tool-title">TRANSPOSITION GENERATOR</div>
            <div className="instruments-title-container animated flipInX">
              <div className="page-title-small">Select Your</div>
              <div className="page-title-large">Instrument's Pitch</div>
            </div>
            <div className="compare-area animated fadeInDown">
              <div className="compare-area-left">
                <div className="converter-underline underline-highlight animated pulse" />
              </div>
              <div className="compare-area-center">
                <img className="middle-arrow-transposition" src={Chevron} width="48" height="48" alt="arrow" />
              </div>
              <div className="compare-area-right">
                <div className="converter-underline" />
              </div>
            </div>
            <div className="instruments-buttons animated fadeInUp">
              <Button variant="outline-info" className="instruments-pitch-button converter-button animated pulse" id="concert" onClick={(event)=>this.handleSelectInstrument(event)}>{this.getText("Concert")}</Button>
              <Button variant="outline-info" className="instruments-pitch-button converter-button animated pulse" id="Bb" onClick={(event)=>this.handleSelectInstrument(event)}>{this.getText("Bb")}</Button>
              <Button variant="outline-info" className="instruments-pitch-button converter-button animated pulse" id="Eb" onClick={(event)=>this.handleSelectInstrument(event)}>{this.getText("Eb")}</Button>
              <Button variant="outline-info" className="instruments-pitch-button converter-button animated pulse" id="F" onClick={(event)=>this.handleSelectInstrument(event)}>{this.getText("F")}</Button>
            </div>
            <Banner />
          </div>
        </Fragment>
      )}
}

export default InstrumentPicker;
