import React from 'react';
import "./Hero.css";
import BG from "../assets/images/home-background.jpg";

export default function Hero() {
  return (
    <section className="hero is-primary">
          <div className="bg-image">
            <img src={BG} />
            <div className="hero-main-text-box">
              <h1> Our masterclasses are available to all!</h1>
              <p>The RCM masterclasses offer both students and music lovers the opportunity to learn with musical artists of the highest calibre.
Our internationally renowned masters provide constructive comments on the performances, and individualised and pertinent advice towards refining your talents on a variety of instruments and different works.
</p>
              <p>
              Check out our <a href="#">online masterclasses</a> and experience how a master’s guidance can help you to improve your performance.
              </p>
            </div>
            <div className="box1">
              <h2>Discover more</h2>
              <p>
              Learn more about the RCM masterclasses by reading some of the frequent questions and accessing our library of free lessons.
              </p>
            </div>
            <div className="box2">
              <h2>Masterclass Schedule</h2>
              <p>
                If you want to have a unique experience check out the schedule of the RCM in-person masterclasses.
              </p>
              <p>
                Members of the public are welcome to observe our master classes free of charge.
              </p>
            </div>
            <div className="box3">
              <h2>Students’ experience</h2>
              <p>
                Curious about student experience?
              </p>
              <p>
                Students who attended a masterclass will share their learnings and the experience of being taught by the very best artists.
              </p>
            </div>
          </div>
    </section>
  )
}
