import React, { Component, Fragment } from 'react';
import Hero from './Hero';
import HomeContent from './HomeContent';

class Home extends Component  {

  componentDidMount() {
    if (this.props.auth.isAuthenticated === false) {
      this.props.history.push("/");
    }
  }
  render() {
    return (
        <Fragment>
          {this.props.auth.isAuthenticated && (
            <div>
              <Hero />
              <HomeContent />
            </div>
          )}

        </Fragment>
      )}
}

export default Home;
