import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar';
import Home from './components/Home';
import LandingPage from './components/LandingPage';
import Products from './components/Products';
import ProductAdmin from './components/ProductAdmin';
import Register from './components/auth/Register';
import LogIn from './components/auth/LogIn';
import ForgotPassword from './components/auth/ForgotPassword';
import ForgotPasswordVerification from './components/auth/ForgotPasswordVerification';
import ChangePassword from './components/auth/ChangePassword';
import ChangePasswordConfirm from './components/auth/ChangePasswordConfirm';
import Welcome from './components/auth/Welcome';
import Footer from './components/Footer';
import WatchVideo from './components/video/WatchVideo';
import ProfileView from './components/profile/ProfileView';
import ProfileEdit from './components/profile/ProfileEdit';
import Masterclass from "./components/video/Masterclass";
import ConcertPitchConverter from "./components/tools/ConcertPitchConverter/ConcertPitchConverter";
import config from "./config";
import Amplify, { Auth } from "aws-amplify";

import { library } from '@fortawesome/fontawesome-svg-core';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
library.add(faEdit);



Amplify.configure({
  Auth: {
    mandatorySignId: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID
  }

});

class App extends Component {

  state = {
    profile: {
      firstname: "loading...",
      lastname: null,
      username: null,
      email: null,
      videos: [],
      instruments: [],
    },
    globalVars: {
      profilePopup: false
    },
    isAuthenticated: false,
    isAuthenticating: true,
    user: null,
    selectedVideoID:null,
  }

  setAuthStatus = authenticated => {
    // console.log("[setAuthStatus] " + authenticated);
    this.setState({ isAuthenticated: authenticated }, () => {
      // console.log("isAuthenticated: " + this.state.isAuthenticated);
    });
    this.updateProfilePopup(false);
  }

  setUser = user => {
    this.setState({ user: user }, () => {
      this.setUserProfile()
    });
  }

  setSelectedVideoID = ID => {
    this.setState({
      selectedVideoID: ID,
    }, ()=> {
      //console.log("selectedVideoID is " + this.state.selectedVideoID);
    })
  }

  signOut = async event => {
    // console.log("[signOut]");
    event.preventDefault();
    try {
      await Auth.signOut();
      this.setAuthStatus(false);
      this.setUser(null);
      this.props.history.push("/login");
    }catch(error) {
      // console.log("error loging out: " + error.message);
    }
  }

  setUserProfile = async event => {
    // console.log("[setUserProfile]");

    if (this.state.user == null) {
      //this.props.history.push("/login");
      return;
    }

    // console.log("retrieving profile for " + JSON.stringify(this.state.user.attributes.email, null, 4));

    const ApiUrl = "https://szo8d8uv4a.execute-api.us-east-1.amazonaws.com/prod/service?email="+this.state.user.attributes.email;

    return fetch(ApiUrl, {
      method: "GET",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => response.json())

    .then((responseJson) => {
      // console.log("responseJson [setUserProfile]: " + JSON.stringify(responseJson));
      const item = responseJson["body"].Item;
      const videos = (item.videos) ? item.videos : this.state.user.videos;
      const instruments = (item.instruments) ? item.instruments : this.state.user.instruments;
      // console.log("videos: " + videos);
      if (videos !== undefined) {
        this.setState({
          profile: {
            firstname: item.firstname,
            lastname: item.lastname,
            username:item.email,
            videos: videos,
            instruments:instruments,
          }
        }, () => {
          // console.log("profile set");
        });
      } else {
        this.setState({
          profile: {
            firstname: item.firstname,
            lastname: item.lastname,
            username:item.email,
          }
        }, () => {
          // console.log("profile set");
        });
      }
    })
    .catch((error) => {
      // console.log("ERROR [setUserProfile]: " + JSON.stringify(error));
    });
  }

  getUserProfile = () => {
    return this.state.profile;
  }

  updateUserProfile = (profileJson) => {
    // console.log("[updateUserProfile] profileJson: " + JSON.stringify(profileJson));
    const ApiUrl = "https://szo8d8uv4a.execute-api.us-east-1.amazonaws.com/prod/service";
    // console.log("this.state.profile is " + JSON.stringify(this.state.profile, null, 4));

    var videos = this.state.profile.videos;
    var instruments = this.state.profile.instruments;

    Object.keys(profileJson).forEach(function(key) {
      switch (key) {
        case "videos":
          videos = profileJson[key];
          break;
          case "instruments":
            instruments = profileJson[key];
            break;
        default:
      }
    });

    // console.log("videos: " + videos);

    let json = {
      "firstname": this.state.profile.firstname,
      "lastname": this.state.profile.lastname,
      "email": this.state.profile.username,
      "videos": videos,
      "instruments": instruments,
    }

    return fetch(ApiUrl, {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(json)
    })
    .then((response) => response.json())

    .then((responseJson) => {
      // console.log("responseJson [updateUserProfile]: " + JSON.stringify(responseJson));

    })
    .catch((error) => {
      // console.log("ERROR [updateUserProfile]: " + JSON.stringify(error));
    });
  }

  updateProfilePopup = open => {
    // console.log("[updateProfilePopup] " + open);
    this.setState({
      globalVars: {
        profilePopup: open
      }
    })
  }

  async componentDidMount() {
    // console.log("[componentDidMount] App.js");

    try{
      const session = await Auth.currentSession();
      this.setAuthStatus(true);
      // console.log("session: " + session);

      const user = await Auth.currentAuthenticatedUser();
      this.setUser(user);
      //console.log("user: " + user);

    }catch(error) {
      // console.log("error: " + error);
    }
    this.setState({
      isAuthenticating: false
    });
  }

  checkForMusicSymbol(str){
    // console.log("[checkForMusicSymbol] str: " + str);
    var newStr = "";
    for (var i = 0; i < str.length; i++) {
      var char = str[i];
      if (char === "#" || char=== "b") {
        char = this.convertMusicSymbol(char);
        newStr += char;
      } else {
        newStr += char;
      }
    }
    // console.log("newStr: " + newStr);
    return newStr;
  }

  convertMusicSymbol(str) {
    // console.log("[convertMusicSymbol] str: " + str);
    return (<span className="dlmbold">+str+</span>);
  }

  render() {
    const authProps = {
      currentConfig: Auth.configure(),
      isAuthenticated: this.state.isAuthenticated,
      user: this.state.user,
      setUser: this.setUser,
      setAuthStatus: this.setAuthStatus,
      profile: this.state.profile,
      setProfile: this.setProfile,
      signOut: this.signOut,
      updateUserProfile:this.updateUserProfile,
      setUserProfile: this.setUserProfile,
      getUserProfile: this.getUserProfile,
      setSelectedVideoID: this.setSelectedVideoID,
      selectedVideoID: this.state.selectedVideoID,
      globalVars: this.state.globalVars,
      updateProfilePopup: this.updateProfilePopup
    }
    return (
      !this.state.isAuthenticating &&
      <div className="App">
        <Router>
          <div>
            <Switch>
              <Route exact path="/" render={(props) => <LandingPage {...props} main={this} auth={authProps}/>}   />
              <Route exact path="/concertpitchconverter" render={(props) => <ConcertPitchConverter {...props}  auth={authProps}/>} />
              <Route exact path="/home" render={(props) => <Home {...props}  auth={authProps}/>} />

              <Route exact path="/products" render={(props) => <Products {...props}  auth={authProps}/>} />
              <Route exact path="/admin" render={(props) => <ProductAdmin {...props}  auth={authProps}/>} />
              <Route exact path="/login" render={(props) => <LogIn {...props}  auth={authProps}/>} />
              <Route exact path="/register" render={(props) => <Register {...props}  auth={authProps}/>} />
              <Route exact path="/forgotpassword" render={(props) => <ForgotPassword {...props}  auth={authProps}/>} />
              <Route exact path="/forgotpasswordverification" render={(props) => <ForgotPasswordVerification {...props}  auth={authProps}/>} />
              <Route exact path="/changepassword" render={(props) => <ChangePassword {...props}  auth={authProps}/>} />
              <Route exact path="/changepasswordconfirm" render={(props) => <ChangePasswordConfirm {...props}  auth={authProps}/>} />
              <Route exact path="/welcome" render={(props) => <Welcome {...props}  auth={authProps}/>} />
              <Route exact path="/watchvideo" render={(props) => <WatchVideo {...props}  auth={authProps}/>} />
              <Route exact path="/profileview" render={(props) => <ProfileView {...props}  auth={authProps}/>} />
              <Route exact path="/profileedit" render={(props) => <ProfileEdit {...props}  auth={authProps}/>} />
              <Route exact path="/masterclass" render={(props) => <Masterclass {...props}  auth={authProps}/>} />

            </Switch>

          </div>
        </Router>
      </div>
    );
  }
}

export default App;

/*
<Navbar auth={authProps}/>
*/
