import React, { Component, Fragment } from 'react';
import ChevronOn from "../../../assets/images/chevron-on.svg";
import TranspositionIcon from "../../../assets/images/icon-transposition.svg";
import TranspositionIconOn from "../../../assets/images/icon-transposition-on.svg";
import InstrumentIcon from "../../../assets/images/icon-instrument.svg";
import InstrumentIconOn from "../../../assets/images/icon-instrument-on.svg";
import InstrumentPitchButton from "../../../assets/images/btn-instrument-pitch.svg";
import InstrumentPitchButtonOn from "../../../assets/images/btn-instrument-pitch-on.svg";
import RefreshIcon from "../../../assets/images/icon-refresh.svg";
import RefreshIconOn from "../../../assets/images/icon-refresh-on.svg";
import "./Transpositions.css";
import { Button, Container, Row, Col } from 'react-bootstrap';

class Transpositions extends Component  {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (this.props.auth.isAuthenticated === false) {
      this.props.history.push("/");
    }
  }

  handleSelectPitch(event) {
    // console.log("[handleSelectPitch]");
    const target = event.target;
    const name = target.name;

    // console.log(" name: " + name + " converter: " + this.props.converter);


    this.props.converter.setState({
      pitch: name,
        }
    );
  }

  updateConverterPage() {
    this.props.converter.setState({
      currPage: "instruments",
    });
  }

  handleBackButtons(event) {
    const target = event.target;
    const name = target.name;
    this.props.converter.setState({
      currPage: name,
    });
  }

  getTranspositionDistance(from, to) {
    // console.log("[getTranspositionDistance] from " + from + " to: " + to);
    var distance;
    switch (from) {
  // for concert instruments
      case "concert":
        switch (to) {
          case "concert":
            distance = 0;
            break;
          case "Bb":
            distance = 2;
            break;
          case "Eb":
            distance = 9;
            break;
          case "F":
            distance = 7;
            break;
          default:
        }
        break;
// for Bb instruments
      case "Bb":
        switch (to) {
          case "concert":
            distance = 10;
            break;
          case "Bb":
            distance = 0;
            break;
          case "Eb":
            distance = 7;
            break;
          case "F":
            distance = 5;
            break;
          default:
        }
        break;
// for Eb instruments
      case "Eb":
        switch (to) {
          case "concert":
            distance = 3;
            break;
          case "Bb":
            distance = 5;
            break;
          case "Eb":
            distance = 0;
            break;
          case "F":
            distance = 10;
            break;
          default:
        }
        break;
// for F instruments
      case "F":
        switch (to) {
          case "concert":
            distance = 5;
            break;
          case "Bb":
            distance = 7;
            break;
          case "Eb":
            distance = 2;
            break;
          case "F":
            distance = 0;
            break;
          default:
        }
        break;
      default:
    }
    return distance
  }

  getTransposition(from, to, note) {
    // console.log("[getTransposition] from " + from + " to " + to + " for " + note);
    let notes = ["C", "C#/Db", "D", "D#/Eb", "E", "F", "F#/Gb", "G", "G#/Ab", "A", "A#/Bb", "B"];

    var distance = this.getTranspositionDistance(from, to);
    // console.log("distance: " + distance);

    let noteIndex = notes.indexOf(note);
    var transposedIndex = noteIndex + distance;
    // console.log("note is " + note + " and the array position is " + noteIndex + " transposedIndex is " + transposedIndex);
    if (transposedIndex >= notes.length) {
      transposedIndex = transposedIndex - notes.length;
    }
    // console.log("transposedIndex after checking: " + transposedIndex);
    var transposedNote = notes[transposedIndex];
    return transposedNote;
    //return this.getNoteText(transposedNote);
  }



  getNoteText(id) {

    let notes = [
      {id:"C", text: "C"},
      {id:"C#/Db", text: "C# / Db"},
      {id:"D", text: "D"},
      {id:"D#/Eb", text: "D# / Eb"},
      {id:"E", text: "E"},
      {id:"F", text: "F"},
      {id:"F#/Gb", text: "F# / Gb"},
      {id:"G", text: "G"},
      {id:"G#/Ab", text: "G# / Ab"},
      {id:"A", text: "A"},
      {id:"A#/Bb", text: "A# / Bb"},
      {id:"B", text: "B"},
    ];
    var text;

    for (let i in notes) {
      let note = notes[i];
      if (note.id === id) {
        text = note.text;
      }
    }
    return text;
  }

  getFontClass(str){
    //console.log("[getFontClass] str: " + str);
    let style = (str === "#" || str === "b") ? "dlmbold" : "";
    return style;
  }

  checkUpperCase(str) {

    if (str === "#" || str === "b"){
      return str.toLowerCase();
    } else {
      return str.toUpperCase();
    }
  }

  getText(text) {
    //console.log("[getText] text: " + text);
    //let newText = this.props.converter.props.main.checkForMusicSymbol(text);
    //console.log("newText: " + newText);

    if (text.length === 1) {
      return (
        <span id={text} className="single-note">{text}</span>
      )
    } else if (text === "concert" || text === "CONCERT") {
      return (
        <span id={text} className="single-note">CONCERT</span>
      )
    } else {
      var strArr = [];
      for (let i in text) {
        var str = text[i];
        //console.log("str: " + str);
        strArr.push(str);
      }
      var newText = strArr.map((str, index) =>
        <span id={text.replace(/\s/g, '')} key={index} className={this.getFontClass(str)}>{str}</span>
      );
      //console.log("newText: " + newText);
      return (
        <div id={text.replace(/\s/g, '')}>{newText}</div>
      );
    }


  }

  getTranspositions() {
    // console.log("[getTranspositions]");

    let note = this.props.converter.state.pitch;
    let instrument = this.props.converter.state.originalInstrument;
    // console.log("selected note is " + note + " and instrument pitch is " + instrument);
    let instruments = [
      {id: "CONCERT", note: this.getTransposition(instrument, "concert", note)},
      {id: "Bb", note: this.getTransposition(instrument, "Bb", note)},
      {id: "Eb", note: this.getTransposition(instrument, "Eb", note)},
      {id: "F", note: this.getTransposition(instrument, "F", note)},
    ];

    let transpositions = instruments.map((instrument, index) =>
        <Col key={index} xs lg="2" className="transposition">
          <div className="note">{this.getText(instrument.note)}</div>
          <div className="instrument">{this.getText(instrument.id)}</div>
        </Col>
    );
    return (
      <Container><Row className="justify-content-md-center transpositions-container animated fadeInUp">{transpositions}</Row></Container>
    );
  }

  onMouseOver(event) {
    const target = event.target;
    const name = target.name;
    // console.log("[onMouseOver] name: " + name);
    if (name === "pitch") {
      this.transpositionIcon.src = TranspositionIconOn;
    } else if (name === "instruments") {
      this.restartIcon.src = RefreshIconOn;
    }
  }

  onMouseOut(event) {
    const target = event.target;
    const name = target.name;
    if (name === "pitch") {
      this.transpositionIcon.src = TranspositionIcon;
    } else if (name === "instruments") {
      this.restartIcon.src = RefreshIcon;
    }
  }

  render() {
    return (
        <Fragment>
          <div className="justify-content-md-center">
            <div className="tool-title">TRANSPOSITION GENERATOR</div>

            <div className="compare-area animated fadeInDown">
              <div className="compare-area-transposition-text">
                {this.getText(this.props.converter.state.originalInstrument)}
              </div>
              <div className="compare-area-center">
                <img className="middle-arrow-transposition" src={ChevronOn} width="48" height="48" alt="arrow" />
              </div>
              <div className="compare-area-transposition-text">
                {this.getText(this.props.converter.state.pitch)}
              </div>
            </div>
                {this.getTranspositions()}
            <div className="navbuttons animated fadeIn">
              <Button variant="outline-secondary" className="back-button btn-instrument  animated pulse" name="instruments" onMouseOver={(event)=>this.onMouseOver(event)} onMouseOut={(event)=>this.onMouseOut(event)} onClick={(event)=>this.handleBackButtons(event)}><img name="instruments" ref={element => this.restartIcon = element} className="button-icon" src={RefreshIcon} width="26.45" height="26.45" alt="icon" />Restart</Button>
            </div>
          </div>
        </Fragment>
      )}
}

export default Transpositions;
