import React, { Component } from 'react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import FormErrors from "../FormErrors";
import LandingPageHeader from "../LandingPageHeader";
import { Auth } from 'aws-amplify';
import "./Register.css";

class Register extends Component {
  state = {
    overlay:false,
    emailAvailable: false,
    usersList: null,
    id:"",
    firstname:"",
    lastname:"",
    username: "",
    zipcode:"",
    password: "",
    confirmpassword: "",
    checked: false,
    errors: {
      cognito: null,
      blankfield: false,
      passwordmatch: false,
      apierrors: null
    },
  }

  RegisterSuccess = () => {
    //console.log("[RegisterSuccess]");

    this.props.history.push("/welcome");
  }

  checkEmail = () => {
    //console.log("[checkEmail] email " + this.state.username);

    this.setState({
      errors: {
        apierrors: "checking your user email..."
      }
    });

    const ApiUrl = "https://szo8d8uv4a.execute-api.us-east-1.amazonaws.com/prod/service?email="+this.state.username;

    let json = {
          "email" : this.state.username
    }
    return fetch(ApiUrl, {
      method: "GET",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => response.json())

    .then((responseJson) => {
      //console.log("responseJson: " + JSON.stringify(responseJson, null, 4));
      const body = responseJson["body"];
      //console.log("body: " + body);
      let item = body.Item;
      //console.log("item: " + JSON.stringify(item, null, 4));
      if (item === undefined) {
        //console.log("responseJson is empty");
        this.setState({
          emailAvailable: true
        });
        return;
      }

      //console.log('email: ' + JSON.stringify(item.email, null, 4));

      if (item.email === undefined) {
        //console.log("responseJson is empty");
        this.setState({
          emailAvailable: true
        });
      } else {
        //console.log("responseJson has content");
        this.setState({
          emailAvailable: false
        });
      }
      this.setState({
        //usersList: list,
      }, () => {
      // TESTIMNG ======

      // ===============
      });
    })
    .catch((error) => {
      //console.log("ERROR: " + JSON.stringify(error));
      this.setState({
        emailAvailable: true
      });
    });
  }

  addUser = () => {
    //console.log("[addUser]");
    const ApiUrl = "https://szo8d8uv4a.execute-api.us-east-1.amazonaws.com/prod/service";
    this.setState({
      errors: {
        apierrors: "adding user..."
      }
    });
    let json = {
      "id": this.state.id,
      "email": this.state.username,
      "firstname": this.state.firstname,
      "lastname": this.state.lastname,
      "zipcode": this.state.zipcode,
    }
    return fetch(ApiUrl, {
      method: "PUT",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(json)
    })
    .then((response) => response.json())

    .then((responseJson) => {
      //console.log("responseJson: " + JSON.stringify(responseJson));
      const body = responseJson["body"];
      let list = body.Items;
      //console.log('list: ' + JSON.stringify(list));
      this.setState({
        //usersList: list,
      }, () => {
      // TESTIMNG ======
      this.RegisterSuccess();
      // ===============
      });
    })
    .catch((error) => {
      //console.log("ERROR: " + JSON.stringify(error));
      this.setState({
        emailAvailable: false,
        errors: {
          apierrors: error
        }
      });
      return;

    });
  }

  clearErrorState = () => {
    this.setState({
      errors: {
        cognito: null,
        blankfield: false,
        passwordmatch: false,
        apierrors: null
      }
    });
  }

  checkFirstNameLastName(event) {
    //console.log("[checkFirstNameLastName]");
    var ret = false;
    let firstname = this.state.firstname;
    let lastname = this.state.lastname;
    let target = event.target;
    //console.log("firstname: " + firstname + " lastname: " + lastname + " target: " + target);

    if (firstname != "" && lastname != "") {
      ret = true;
    } else {

      var err;
      if (firstname === "") {
        err = "Please enter your first name."
      } else if (lastname === "") {
        err = "Please enter your last name."
      }
      this.setState({
        errors: {
          apierrors: err
        }
      });

    }
    return ret;
  }

  handleSubmit = async event => {
    //console.log("[handleSubmit]");
    event.preventDefault();

    // Form validation
    this.clearErrorState();

    let checkNames = this.checkFirstNameLastName(event);
    //console.log("checkNames: " + checkNames);
    if (checkNames == false) {
      return;
    }

    // check ZIP / Postal Code

    //console.log("zipcode: " + this.state.zipcode);
    if (this.state.zipcode === "" || this.state.zipcode === null) {
      this.setState({
        errors: {
          apierrors: "Please enter your ZIP / Postal Code"
        }
      });
      return;
    }

    this.setState({
      errors: {
        apierrors: "checking ZIP / Postal Code..."
      }
    });

    let checkZIP = this.state.zipcode.match(/^\d{5}(-\d{4})?(?!-)$/);
    //console.log("checkZIP: " + checkZIP);

    let checkPostalCode = this.state.zipcode.match(/[a-zA-Z][0-9][a-zA-Z](-| |)[0-9][a-zA-Z][0-9]/);
    //console.log("checkPostalCode: " + checkPostalCode);

    var checkCode;

    if (checkZIP != null) {
      checkCode = checkZIP;
    } else if (checkPostalCode != null) {
      checkCode = checkPostalCode;
    }

    //console.log("checkCode: " + checkCode);

    if (checkCode === undefined || checkCode === null) {
      this.setState({
        errors: {
          apierrors: "Please enter valid ZIP / Postal Code"
        }
      });
      return;
    }

    // await this.checkUserName();
    if (!this.state.username.includes("@")) {
      //console.log("email doesn't include @");
      this.setState({
        emailAvailable: false,
        errors: {
          apierrors: "Please enter a valid email address."
        }
      });
      return;
    }

    await this.checkEmail();

    //console.log("emailAvailable: " + this.state.emailAvailable);
    if (this.state.emailAvailable) {
      //console.log("email doesn't exist.");
    } else {
      const err = "email already exists.";
      //console.log(err);
      this.setState({
        errors: {
          apierrors: err
        }
      });
      return;
    }

    const pass = this.state.password;

    if (pass.length < 8) {
      this.setState({
        errors: {
          apierrors: "Your password should contain at least 8 characters"
        }
      });
      return;
    }

    const confirmedpass = this.state.confirmpassword;

    if (pass === "") {
      this.setState({
        errors: {
          apierrors: "Please create a password"
        }
      });
      return;
    }

    if (pass !== confirmedpass) {
      this.setState({
        errors: {
          apierrors: "Passwords don't match"
        }
      });
      return;
    }

    // check the agreement checkbox
    if (!this.state.checked) {
      this.setState({
        errors: {
          apierrors: "Please agree to the terms and conditions."
        }
      });
      return;
    }

    this.setState({
      errors: {
        apierrors: "signing up..."
      }
    });

    // AWS Cognito integration here
    const {username, password} = this.state;
    try {
      const signUpResponse = await Auth.signUp({
        username,
        password
      });

    //  console.log("signUpResponse: " + JSON.stringify(signUpResponse, null, 4));

    //  console.log("userSub: " + signUpResponse.userSub);
      this.setState({
        id: signUpResponse.userSub,
      }, () => {
      // TESTIMNG ======
      this.addUser();
      // ===============
      });
    }catch(error){
      let err = null;
      !error.message ? err = { "message": error } : err = error;
      this.setState({
        errors: {
          ...this.state.errors,
          cognito: err
        }
      });
    }
  };

  onInputChange = event => {
    if (event.target.id === "username") {
      console.log("it's username");
      event.target.value = event.target.value.toString().toLowerCase();
    }
    this.setState({
      [event.target.id]: event.target.value
    });
    document.getElementById(event.target.id).classList.remove("is-danger");
  }

  handleCheckbox = event => {
  //  console.log("[handleCheckbox]");
    if(this.state.checked !== event.target.checked) {
       this.setState({
         checked:event.target.checked
       });
    }
  }

  handleGoToLogIn = () => {
  //  console.log("[handleGoToLogIn]");
    this.props.history.push('/login');
  }


  render() {
    return (
      <div className="container">
        <LandingPageHeader />
        <div className="landing-main">
          <div className="landing-title">Register</div>
        </div>
        <Form>
        <Container className="form-container">
          <Row  className="justify-content-md-center">
            <Col className="form-field">
              <FormErrors formerrors={this.state.errors} apierrors={this.state.errors.apierrors}/>
            </Col>
          </Row>
          <Row  className="justify-content-md-center">
            <Col className="form-field">
              <Container>
                <Row className="small-container justify-content-md-center">
                  <Col className="field-left form-field input-small">
                    <div className="field-area input-small">
                      <div className="field-title input-small">First Name</div>
                      <input
                        className="form-input input-small"
                        type="text"
                        id="firstname"
                        aria-describedby="userNameHelp"
                        value={this.state.firstname}
                        onChange={this.onInputChange}
                      />
                    </div>
                  </Col>
                  <Col className="form-field input-small field-right ">
                  <div className="field-area input-small">
                    <div className="field-title input-small">Last Name</div>
                    <input
                      className="form-input input-small"
                      type="text"
                      id="lastname"
                      aria-describedby="userNameHelp"
                      value={this.state.lastname}
                      onChange={this.onInputChange}
                    />
                  </div>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
          <Row  className="justify-content-md-center">
            <Col className="form-field">
              <div className="field-area">
                <div className="field-title">ZIP / Postal Code</div>
                <input
                  className="form-input"
                  type="text"
                  id="zipcode"
                  value={this.state.zipcode}
                  onChange={this.onInputChange}
                />
              </div>
            </Col>
          </Row>
          <Row  className="justify-content-md-center">
            <Col className="form-field">
              <div className="field-area">
                <div className="field-title">E-mail</div>
                <input
                  className="form-input"
                  type="text"
                  id="username"
                  aria-describedby="emailHelp"
                  value={this.state.username}
                  onChange={this.onInputChange}
                />
              </div>
            </Col>
          </Row>
          <Row  className="justify-content-md-center">
            <Col className="form-field">
              <div className="field-area">
                <div className="field-title">Password</div>
                  <input
                    className="form-input"
                    type="password"
                    id="password"
                    value={this.state.password}
                    onChange={this.onInputChange}
                  />
                  <div className="warning">*Your password should contain at least 8 characters</div>
              </div>
            </Col>
          </Row>
          <Row  className="justify-content-md-center">
            <Col className="form-field">
              <div className="field-area">
                <div className="field-title">Confirm password</div>
                  <input
                    className="form-input"
                    type="password"
                    id="confirmpassword"
                    value={this.state.confirmpassword}
                    onChange={this.onInputChange}
                  />
                  <div className="warning"><input type="checkbox" checked={this.state.checked} onChange={this.handleCheckbox}/> I agree to receive email updates and communication from the RCM. I can unsubscribe at any time.</div>
              </div>

            </Col>
          </Row>
          <Row>
            <Col className="form-field">
              <Container className="register-buttons-area">
                <Row className="small-container justify-content-md-center">
                  <Col className="field-left form-field input-small">
                    <div className="field-area input-small">
                      <div>
                        <Button id="login" className="landing-button register-button button-left" onClick={ this.handleSubmit }>Register</Button>
                      </div>
                    </div>
                  </Col>
                  <Col className="form-field input-small text-right">
                  <div className="field-area input-small">
                    <div>
                      <a className="link button-right" onClick={this.handleGoToLogIn}>Log in</a>
                    </div>
                  </div>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
        </Form>
      </div>
    );
  }
}

export default Register;

/*
<div className="field">
  <p className="control">
    <input
      className="input"
      type="text"
      id="username"
      aria-describedby="userNameHelp"
      placeholder="Enter username"
      value={this.state.username}
      onChange={this.onInputChange}
    />
  </p>
</div>
*/
