import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import Amplify, { Auth, Storage } from "aws-amplify";
import InfiniteScroll from 'react-infinite-scroll-component';
import VideoListCell from './VideoListCell';

class VideoListMain extends Component {

  constructor(props) {
    super(props);
    this.state = {
      profile: null,
      items: this.props.items,
    }
  }

  componentDidMount() {

  }

  fetchMoreData = () => {
    // a fake async api call like which sends
    // 20 more records in 1.5 secs
    /*
    setTimeout(() => {
      this.setState({
        items: this.state.items.concat(Array.from({ length: 20 }))
      });
    }, 1500);

    */
  };

  videoSelected = async fileID => {
    console.log("[videoSelected] fileID: " + fileID);
    this.props.parent.loadVideo(fileID);
  }

  render() {
    return(
      <InfiniteScroll
        dataLength={this.props.items.length}
        next={this.fetchMoreData}
        hasMore={true}
        //loader={<h4>{(this.props.items.length === 0) ? "the list is empty." : "loading..."}</h4>}
        height={this.props.height}
      >
        {this.props.items.map((i, index) => (
          <VideoListCell videoList={this} auth={this.props.auth} item={i} key={index} />
        ))}
      </InfiniteScroll>
    )
  }
}

export default withRouter(VideoListMain);
