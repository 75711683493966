import React, { Component, Fragment } from 'react';
import InstrumentPicker from "./InstrumentPicker";
import PitchPicker from "./PitchPicker";
import Transpositions from "./Transpositions";
import Navbar from '../../Navbar';
import "./ConcertPitchConverter.css";

class ConcertPitchConverter extends Component  {

  constructor(props) {
    super(props);
    this.state = {
      height:0,
      originalInstrument: "concert",
      pitch: "C",
      currPage: "instruments"
    }
  }

  componentDidMount() {
    if (this.props.auth.isAuthenticated === false) {
      this.props.history.push("/");
      return;
    }
  }

  render() {
    return (
      <div>
        <Navbar auth={this.props.auth}/>
          <div className="tool-container" ref={ (divElement) => this.divElement = divElement}>
            {this.props.auth.isAuthenticated && this.state.currPage === "instruments" && (
                <InstrumentPicker converter={this} auth={this.props.auth}  />
            )}
            {this.props.auth.isAuthenticated && this.state.currPage === "pitch" && (
                <PitchPicker converter={this} auth={this.props.auth}   />
            )}
            {this.props.auth.isAuthenticated && this.state.currPage === "transpositions" && (
                <Transpositions converter={this} auth={this.props.auth}   />
            )}
          </div>
      </div>
    )}
}

export default ConcertPitchConverter;
