import React, { Component } from 'react';
import "./Banner.css";

class Banner extends Component {
  render() {
    return(
      <div className="ad animated fadeIn">
        <div className="copy">

          <div className="text">
            <h2>Master Music Theory</h2>
               <p>
                  with The Royal Conservatory's<br />
                  full line-up of Digital Learning<br />
                  products.<br />
                </p>
          </div>
        <p className="ad-button"><a target="_blank" href="https://www.rcmusic.com/learning/digital-learning/online-theory-tool">Start Now</a></p>
        </div>
       </div>
    );
  }
}

export default Banner;
