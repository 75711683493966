import React, { Component } from 'react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import FormErrors from "../FormErrors";
import Validate from "../utility/FormValidation";
import LandingPageHeader from "../LandingPageHeader";
import { Auth } from 'aws-amplify';
import "./Login.css";

class LogIn extends Component {

  componentDidMount() {
    if (this.props.auth.isAuthenticated === true) {
      this.props.history.push("/");
      return;
    }
  }

  state = {
    canLogin:true,
    profile:null,
    username: "",
    password: "",
    id:"",
    errors: {
      cognito: null,
      blankfield: false
    }
  };

  clearErrorState = () => {
    this.setState({
      errors: {
        cognito: null,
        blankfield: false
      }
    });
  };

  resetLogIn = () => {
    this.setState({
      canLogin: true
    });
  }

  handleSubmit = async event => {
    //console.log("[handleSubmit]");
    event.preventDefault();

    await this.resetLogIn();

    // check if the user ID is an email
    if (!this.state.username.includes("@")) {
      console.log("email doesn't include @");
      this.setState({
        canLogin: false,
        errors: {
          apierrors: "Please enter a valid email address."
        }
      });
      return;
    } else {
      this.setState({
        canLogin: true,
      });
    }

    if (!this.state.canLogin) {
      return;
    }

    await this.checkUserProfile();

    // Form validation

    if (!this.state.canLogin) {
      return;
    }

    const pass = this.state.password;

    if (pass.length < 8) {
      this.setState({
        canLogin: false,
        errors: {
          apierrors: "Please enter your correct password"
        }
      });
      return;
    }

    if (!this.state.canLogin) {
      return;
    }

    this.clearErrorState();
    /*
    const error = Validate(event, this.state);
    if (error) {
      this.setState({
        errors: { ...this.state.errors, ...error }
      });
    }
    */



    // AWS Cognito integration here
    try {
      this.setState({
        errors: {
          apierrors: "signing in..."
        }
      });
      //console.log("signing in with " + this.state.username);
      //console.log("Auth configure: " + JSON.stringify(Auth.configure(), null, 4));
      const user = await Auth.signIn(this.state.username, this.state.password);

      //console.log("user: " + JSON.stringify(user, null, 6));
      this.props.auth.setAuthStatus(true);
      this.props.auth.setUser(user);
      this.props.history.push("/concertpitchconverter");
    }catch(error){
      //console.log("error signing in: " + JSON.stringify(error, null, 4));
      let err = null;
      !error.message ? err = { "message": error } : err = error;
      this.setState({
        canLogin: false,
        errors: {
          apierrors: error.message,
          cognito: err
        }
      });
    }
  };

  /*
  getUserID = async () => {
    console.log("[getUserID]");
    const ApiUrl = "https://szo8d8uv4a.execute-api.us-east-1.amazonaws.com/prod/service?username="+this.state.username;

    return fetch(ApiUrl, {
      method: "GET",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => response.json())

    .then((responseJson) => {
      //console.log("responseJson [getUserID]: " + JSON.stringify(responseJson));

    })
    .catch((error) => {
      console.log("ERROR [getUserID]: " + JSON.stringify(error));

    });
  }
*/
  checkUserProfile = () => {
    //console.log("[checkUserProfile] username: " + this.state.username);
    this.setState({
      canLogin: false,
      errors: {
        apierrors: "checking the user ID..."
      }
    });
    const ApiUrl = "https://szo8d8uv4a.execute-api.us-east-1.amazonaws.com/prod/service?email="+this.state.username;

    return fetch(ApiUrl, {
      method: "GET",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((response) => response.json())

    .then((responseJson) => {
      //console.log("responseJson: " + JSON.stringify(responseJson, null, 4));

      const body = responseJson["body"];
      const item = body.Item;

      if (item === undefined) {
        this.setState({
          canLogin: false,
          errors: {
            apierrors: "this user doesn't exist."
          }
        });
        return;
      } else {
        this.setState({
          canLogin: true
        });
      }

      //const id = body.Item.id;
      //console.log("item is " + item);
      //console.log("user in App is " + this.state.user);
    })
    .catch((error) => {
      console.log("ERROR [checkUserProfile]: " + JSON.stringify(error));
      this.setState({
        canLogin: false,
        errors: {
          apierrors: JSON.stringify(error)
        }
      });
      return;
    });
  }

/*
  addUserProfile = () => {
    console.log("[addUserProfile]");
    const ApiUrl = "https://szo8d8uv4a.execute-api.us-east-1.amazonaws.com/prod/service";
    let json = {
      "username": this.state.username,
      "email": this.state.email
    }
    return fetch(ApiUrl, {
      method: "PUT",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(json)
    })
    .then((response) => response.json())

    .then((responseJson) => {
      //console.log("responseJson: " + JSON.stringify(responseJson));

      const body = responseJson["body"];
      const item = body.Item;
      console.log("item is " + item);
      this.props.auth.setProfile(item);

    })
    .catch((error) => {
      console.log("ERROR [addUserProfile]: " + JSON.stringify(error));
    });
  }
*/
  onInputChange = event => {
    if (event.target.id === "username") {
      event.target.value = event.target.value.toString().toLowerCase();
    }
    this.setState({
      [event.target.id]: event.target.value
    });
    document.getElementById(event.target.id).classList.remove("is-danger");
  };

  handleGoToRegister = () => {
    //console.log("[handleGoToRegister]");
    this.props.history.push('/register');
  }

  handleForgotPassword = () => {
    this.props.history.push('/forgotpassword');
  }

  render() {
    return (
        <div className="container">
          <LandingPageHeader />
          <div className="landing-main">
            <div className="landing-title">Log In</div>
          </div>

          <Container className="form-container">
            <Row  className="justify-content-md-center">
              <Col className="form-field">
                <FormErrors formerrors={this.state.errors} apierrors={this.state.errors.apierrors}/>
              </Col>
            </Row>
            <Row  className="justify-content-md-center">
              <Col className="form-field">
                <div className="field-area">
                  <div className="field-title">E-mail</div>
                  <input
                    className="form-input"
                    type="text"
                    id="username"
                    aria-describedby="emailHelp"
                    value={this.state.username}
                    onChange={this.onInputChange}
                  />
                </div>
              </Col>
            </Row>
            <Row  className="justify-content-md-center">
              <Col className="form-field">
                <div className="field-area">
                  <div className="field-title">Password</div>
                    <input
                      className="form-input"
                      type="password"
                      id="password"
                      value={this.state.password}
                      onChange={this.onInputChange}
                    />
                    <div className="field">
                      <p className="control">
                        <a className="warning"  onClick={this.handleForgotPassword}>Forgot password?</a>
                      </p>
                    </div>
                </div>
              </Col>
            </Row>

            <Row>
                <Col className="form-field">
                  <Container className="register-buttons-area">
                    <Row className="small-container justify-content-md-center">
                      <Col className="field-left form-field input-small">
                        <div className="field-area input-small">
                          <div>
                            <Button id="login" className="landing-button register-button button-left" onClick={ this.handleSubmit }>Log in</Button>
                          </div>
                        </div>
                      </Col>
                      <Col className="form-field input-small text-right">
                      <div className="field-area input-small">
                        <div>
                          <a className="link button-right" onClick={this.handleGoToRegister}>Register</a>
                        </div>
                      </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="spacer-bottom" />
                      </Col>
                    </Row>
                  </Container>
                </Col>
            </Row>
          </Container>
        </div>
    );
  }
}

export default LogIn;
