import React, { Component}  from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import LandingPageHeader from "./LandingPageHeader";
import TranspositionIcon from "../assets/images/icon-transposition.svg";
import Banner from "./banner/Banner";
import "./LandingPage.css";
class LandingPage extends Component {

  componentDidMount() {
    if (this.props.auth.isAuthenticated === true) {
      this.props.history.push("/concertpitchconverter");
      return;
    }
  }

  handleButtonAction = event => {
    // console.log("[profilePopup]");
    const target = event.target;
    const id = target.id;
    // console.log("id: " + id);
    if (id === "login") {
      this.props.history.push("/login");
    } else if (id === "register") {
      this.props.history.push("/register");
    }
  }

  render() {
    return (
        <div className="container">
          <LandingPageHeader />
          <div className="landing-main">
            <div className="landing-icon">
              <img src={TranspositionIcon} width="87.5" height="49.31" alt="transition icon" />
            </div>
            <div className="landing-rcm-text">Royal Conservatory of Music</div>
            <div className="landing-title">Transposition Generator</div>

            <Container>
              <Row className="justify-content-md-center landing-buttons">
              <Col xs lg="2">
                <Button className="landing-button-alt" id="register" onClick={ this.handleButtonAction }>Register</Button>
              </Col>
              <Col xs lg="2">
                <Button id="login" className="landing-button" onClick={ this.handleButtonAction }>Login</Button>
              </Col>
              </Row>
            </Container>
            <Banner />
            <div className="spacer-bottom" />
          </div>
        </div>
    )
  }
}

export default LandingPage;
